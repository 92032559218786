import ARPipeline from "scripts/pipeline/ARPipeline";

export default new (class {
  load = ({type = 'image'}) => {
    this.type = type

    return new Promise((resolve) => {
      Promise.all([
        new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.XR8 && window.XRExtras) {
              clearInterval(interval);
              resolve();
            }
          }, 50);
        }),
      ]).then(() => {

        window.XRExtras.Loading.showLoading()
        // customizeUI()
        this.initPipeline().then(resolve)
      })
    })
  }

  initPipeline = () => new Promise(resolve => {

    window.XR8.XrController.configure({ 
      disableWorldTracking: false, //this.type === 'image',
    })

    const pipelines = []
    if(this.type === 'image') {
      pipelines.push(window.XR8.XrController.pipelineModule())
    }

    window.XR8.addCameraPipelineModules([
      ...pipelines,
      window.XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
      window.XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
      window.XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
      window.XRExtras.RuntimeError.pipelineModule(), 
      ARPipeline,

    ])
    
    // const check = setInterval(() => {
    //   console.log(window.XRStarted)
    //   if (window.XRStarted) {
    //     console.log("loading completed");
    //     EventBus.dispatch("Action", { type: "loadingDone" });
    //     clearInterval(check)
    //     resolve()
    //   }
    // }, 50)

    resolve()

  })

})()
