import React from 'react'
import s from './Screen.module.scss'


const Screen = ({children}) => {

  return (
    <div className={s.Screen}>
      {children}
    </div>
  )

}

export default Screen