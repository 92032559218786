import React from 'react'
import s from './Contact.module.scss'

import logoWithWords from 'assets/8th-wall/logo-with-words.png';
import arrow from 'assets/8th-wall/fancy-down-arrow.png';
import skiTips from 'assets/8th-wall/ski-tips.png';
import clouds from 'assets/8th-wall/clouds3.png';
import topBoxes from 'assets/8th-wall/mountain-img-boxes.png';
import bottomBoxes from 'assets/8th-wall/ending-boxes.png';

const Contact = ({type, children}) => {

  return (
    <div className={s.Contact}>
      <div className={s.journey}>
        <img src={logoWithWords} alt="Mountain Logo: HEFE Backcountry" className={s.mountainLogo} />
        <h4 className={s.red}>Choose your guide</h4>
        <h3 className={s.white}>And Begin the</h3>
        <h2 className={s.white}>Journey</h2>
        <img src={arrow} alt="Arrow" className={s.arrow} />
      </div>
      <img src={skiTips} alt="ski tips" className={s.skiTips} />
      <img src={clouds} alt="clouds" className={s.clouds} />
      <div className={s.guides}>
        <div>
          <h3>The guides</h3>
          <h5>Friendly. Experienced. Easy on the eyes.</h5>
        </div>
        <div className={s.contacts}>
          <div>
            <h5>Contact</h5>
            <a className={s.btn} href="mailto:rj@14four.com?subject=Interested in AR?">RJ</a>
            <p>- GUIDE -</p>
          </div>
          <div>
            <h5>Contact</h5>
            <a className={s.btn} href="mailto:jeff@14four.com?subject=Interested in AR?">Jeff</a>
            <p>- GUIDE -</p>
          </div>
          <div>
            <h5>Contact</h5>
            <a className={s.btn} href="mailto:eric@14four.com?subject=Interested in AR?">Eric</a>
            <p className={s.hospitality}>-&nbsp;HOSPITALITY&nbsp;-</p>
          </div>
        </div>
      </div>
      <div className={s.topBoxes}>
        <img src={topBoxes} alt="Snowy mountain" />
      </div>
      <div className={s.placesSection}>
        <h3>the Places</h3>
        <h5>always better than the last</h5>
        <div className={s.places}>
          <div className={s.america}>
            <span className={s.btn}>America</span>
          </div>
          <div className={s.europe}>
            <span className={s.btn}>Europe</span>
          </div>
          <div className={s.asia}>
            <span className={s.btn}>Asia</span>
          </div>
        </div>
      </div>
      <div className={s.bottomBoxes}>
        <img src={bottomBoxes} alt="boxes" />
      </div>
      <div className={s.footer}>
        <h5>made by</h5>
        <h3>14Four</h3>
      </div>
    </div>
  )
}

export default Contact