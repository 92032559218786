/* eslint-disable */
import React, { useState } from 'react'
import s from './Desktop.module.scss'
import AsideSummary from 'components/wireframe/AsideSummary'
import CopySection from 'components/wireframe/CopySection'
import { Waypoint } from 'react-waypoint';


const Desktop = ({children, setShowModal}) => {
  const [play1, setPlay1] = useState(true)
  const [play2, setPlay2] = useState(true)

  const handleWaypointEnter = (num) => {
    switch(num) {
      case 1:
        setPlay1(true)
        return
      case 2:
        setPlay2(true)
        return
      default:
        return
    }
  }

  return (
    <div className={s.Desktop}>
      <aside className={s.sideMenu}>
        <div/><div/><div/><div/><div/><div/><div/>
      </aside>
      <div className={s.inner}>
        <div className={s.header}>
          <div/><div/><div/><div/><div/>
        </div>
        <div className={s.page}>
          <div className={s['main-content']}>
            {/* <Waypoint onEnter={() => { handleWaypointEnter(1) }} /> */}
            {play1 &&
              <div className={s["banner-wrapper"]}>
                <iframe
                  className={s.bannerTop}
                  title="Desktop Banner"
                  frameBorder="0"
                  src="animation/desktop/728x90/index.html"
                  />
                <div className={s['tooltip-top']}>
                  <p>Smaller banners will launch an AR experience that requires surface detection to view, like a wall or desktop.</p>
                </div>
                <div className={s.hit} onClick={() => {
                  // window.open('/image')
                  if (setShowModal) {
                    setShowModal(true)
                  }
                }} />
              </div>
            }
            <section>
              <div className={s.headline}/>
              <div className={s.copy}/>
              <div className={s.copy}/>
            </section>
            <section>
              <div className={s.image}/>
              <div className={s.caption}/>
            </section>
            <section>
              <CopySection/>
            </section>
            <section style={{marginBottom: '80px'}}>
              <CopySection/>
            </section>
          </div>
          <aside>
            <AsideSummary />
            <AsideSummary />
            <AsideSummary />
            <div className={s["banner-wrapper"]}>
              {/* <Waypoint onEnter={() => { handleWaypointEnter(2) }} /> */}
              {play2 && <iframe className={s.bannerSide} title="Desktop Banner" frameBorder="0" src="animation/desktop/300x600/index.html" />}
              <div className={s['tooltip-side']}>
                <p>On larger banner sizes we can utilize the banner as a target to anchor the AR experience, this can also be used to create some interesting effects.</p>
              </div>
              <div className={s.hit} onClick={() => {
                // window.open('/image')
                if (setShowModal) {
                  setShowModal(true)
                }
              }} />
            </div>
          </aside>
        </div>
        <div className={s.footer}></div>
      </div>
    </div>
  )

}

export default Desktop
