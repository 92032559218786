import React from 'react'
import s from './AsideSummary.module.scss'


const AsideSummary = () => {

  return (
    <div className={s.AsideSummary}>
      
      <div className={s['copy-wrapper']}>
        <div className={s.headline}/>
        <div className={s['copy-long']}/>
        <div className={s['copy-short']}/>
      </div>
      <div className={s.image}/>
    </div>
  )

}

export default AsideSummary