import React from 'react'
import cx from 'classnames'
import s from './Modal.module.scss'
import closeIcon from 'assets/close-icon.png'

const Modal = ({children, active, close}) => {
  
  return (
    <div className={cx(s.Modal, {[s.active]: active})}>
      <div className={s.innerWrapper}>
        <div className={s.inner}>
          <div>
            <button onClick={close}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
          <div className={s.text}>
            <h3>Slow down there!</h3>
            <p>This is an AR banner demonstration,<br/>scan the QR code with your phone. Then you can click through from the expanded banner&nbsp;experience</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal