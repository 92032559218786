import React, { useState } from 'react'
import s from './Mobile.module.scss'
import CopySection from 'components/wireframe/CopySection'
import { Waypoint } from 'react-waypoint';

const Mobile = ({children}) => {

  const [play, setPlay] = useState(false)

  const handleWaypointEnter = () => {
    setPlay(true)
  }

  return (
    <div className={s.Mobile}>
      <div className={s.header}>
        <div/><div className={s.hamburger}/>
      </div>
      <div className={s.page}>
        <section className={s.sectionImage}>
          <div className={s.image}/>
        </section>
        <section>
          <div className={s.headline}/>
          <div className={s.copy}/>
          <div className={s.copy}/>
        </section>
        <section>
          <div className={s["banner-wrapper"]}>
            <Waypoint onEnter={() => { handleWaypointEnter() }} />
            {play &&
              <div className={s["banner-wrapper"]}>
                <iframe
                  className={s.banner}
                  title="Mobile Banner"
                  frameBorder="0"
                  src="animation/mobile/320x100/index.html"
                />
                <div className={s.hit} onClick={() => {
                  // window.open('/world')
                  window.open('https://hefebackcountry.com/world')
                }} />
              </div>
            }
          </div>
        </section>
        <section style={{marginBottom: '32px'}}>
          <CopySection/>
        </section>
        <section style={{marginBottom: '150px'}}>
          <CopySection/>
        </section>
      </div>
      <div className={s.footer}></div>
    </div>
  )

}

export default Mobile
