import React from 'react'
import './App.scss';

import Home from 'views/Home'
import Contact from 'views/Contact'
import Experience from 'views/Experience'
import NotFound from 'views/NotFound'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/image">
            <Experience type='image' />
          </Route>
          <Route exact path="/world">
            <Experience type='world' />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route path="/*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
