import React from 'react'
import s from './CopySection.module.scss'


const CopySection = () => {

  return (
    <div className={s.CopySection}>
      <div/><div/><div/><div/><div/><div/><div/><div/>
    </div>
  )

}

export default CopySection