import React, { useEffect, useState} from 'react'
import s from './Experience.module.scss'

import ExperienceLoader from 'scripts/loaders/ExperienceLoader'
import ARLoader from 'scripts/loaders/ARLoader'
import ARLayout from 'layouts/AR'
import Screen from 'components/ar/Screen'
import EventBus from 'scripts/EventBus';
import MountainLogoSVG from 'assets/mountain-logo.svg';
import EighthWallLogoPNG from 'assets/8th-wall/8thWall-logo.png';


const Experience = ({type, children}) => {
  let experience = React.useRef({});
  let [placed, setPlaced] = useState(false);
  let [playing, setPlaying] = useState(false);
  let [loading, setLoading] = useState(true);
  let [imageType] = useState(type==="image");
  let [targetLost,setTargetLost] = useState(true);
  let [targetFirstTime,setTargetFirstTime] = useState(false);
  let [heliDone, setHeliDone] = useState(false);
  let [muteUnlocked, setMuteUnlocked] = useState(false);
  let [muted, setMuted] = useState(true);

  useEffect(() => {
    startExperience(type)
  }, []) // eslint-disable-line

  const startExperience = (type) => {
    EventBus.on('UserTouch', handleUserTouch)
    EventBus.on('Action', handleAction)
    ARLoader.load({ type }).then(() => {
        ExperienceLoader.load("Main").then(onLoadModule)     
    })
  }

  const onLoadModule = (module) => {
    experience.current = new module.default()
    const canvas = document.getElementById("ar-canvas")
    experience.current.initPipeline(canvas, type);
    experience.current.load().then(() => {
      experience.current.initExperience(canvas, type)     
      // window.addEventListener("touchstart",(e) => {experience.current.handleTouches(e,"start");},true);
      // window.addEventListener("touchmove",(e) => {e.preventDefault();experience.current.handleTouches(e,"move");},true);
      // window.addEventListener("touchend",(e) => {experience.current.handleTouches(e,"end");},true);
      // window.onfocus = function() {experience.current.focus();}
      // window.onblur = function() {experience.current.blur();}
      // setTimeout(() => {
      //   setLoading(false);
      // }, 1500);
    })
  }

  const handleUserTouch = (payload) => {
    // handle user touch events
    if(payload.type === 'placed') setPlaced(true);
  }

  const handleAction = (payload) => {
    // handle any actions from UI
    if(payload.type==="targetLost") setTargetLost(true);
    if(payload.type==="targetFound") setTargetLost(false);
    if(payload.type==="targetFirstTime") setTargetFirstTime(true);
    if(payload.type==="heliDone") setHeliDone(true);
    if(payload.type==="muteUnlocked") setMuteUnlocked(true);
    if(payload.type==="mute") setMuted(true);
    if(payload.type==="unmute") setMuted(false);
    //LOADING DONE
    if(payload.type==="loadingDone") {
      setLoading(false);
      window.addEventListener("touchstart",(e) => {experience.current.handleTouches(e,"start");},true);
      window.addEventListener("touchmove",(e) => {e.preventDefault();experience.current.handleTouches(e,"move");},true);
      window.addEventListener("touchend",(e) => {experience.current.handleTouches(e,"end");},true);
      window.onfocus = function() {experience.current.focus();}
      window.onblur = function() {experience.current.blur();}
    }
  }

  const beginAnimatingExperience = () => {
    experience.current.beginAnimatingExperience();
    experience.current.playSound();
    setPlaying(true);
    setTargetFirstTime(false);
  }

  const resetExperience = () => {
    experience.current.resetExperience();
    setPlaced(false);
    setPlaying(false);
  }

  const mute = () => {
    experience.current.mute();
    setMuted(true);
  }

  const unmute = () => {
    experience.current.unmute();
    setMuted(false);
  }

  let buttonClass = "invisible button";
  let buttonText = "";
  let buttonAction = null;
  let helpClass="invisible";
  let helpText ="";

  console.log("IMGTYPE" + imageType);

  if(!placed && !playing)
  {
    buttonClass="invisible button";
    helpClass="";
    helpText="Tap to Place";
  } 
  else if(placed && !playing)
  {
    buttonClass="button";
    buttonText="Start Experience"
    buttonAction = beginAnimatingExperience; 
    helpClass="";
    helpText="Pinch to Scale";
  }
  else if(placed && playing && heliDone)
  {
    buttonClass="button buttonSecondary";
    buttonText="Reset Experience";
    buttonAction = resetExperience;
    helpClass="invisible";
    helpText="";
  }

  if(imageType)
  {
    buttonClass="invisible button"
    helpText="Point Phone at Banner"
    helpClass=""
    //check for target lost
    if(!targetLost)
    {
      helpClass="invisible";
      helpText="";
    }
    if(targetFirstTime){
      buttonClass="button";
      buttonText="Start Experience"
      buttonAction = beginAnimatingExperience; 
    }
  }

  console.log(s)
  return (
    <ARLayout handleAction={handleAction}>
      <div className={s.Experience}>
        <Screen>
          {loading ? <section className={s.loadingScreen}>
            <h4>Hold On</h4>
            <div className={s.dots}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <h3>Reaching the Peak</h3>
            <img src={MountainLogoSVG} alt="Mountain Logo" class={s.mountainLogo} />
            <p>Prepare to be amazed.</p>
            <img src={EighthWallLogoPNG} alt="8th Wall" className={s.eighthWallLogo} />
          </section> : null}
          {!loading ? <>

            {(muteUnlocked) ? <div>
              {(muted) ? <div>
              <button onClick={unmute} className="babylonUnmuteBtn unmute"></button>
            </div> : <div>
              <button onClick={mute} className="babylonUnmuteBtn mute"></button>
            </div>}
            </div> : null}

            {helpText ? <div className={`${s.arSetupInstructions} ${helpClass}`}>
              <h5>{helpText}</h5>
            </div> : null}
            {buttonText ? <div className={`${s.buttonWrapper} ${buttonClass}_wrapper`}>
              <button onClick={buttonAction} className={`${s.arActionButton} ${buttonClass}`}>
                {buttonText}
              </button>
            </div> : null}
          </> : null}
        </Screen>
      </div>
    </ARLayout>
  )

}

export default Experience