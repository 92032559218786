import React, { useState } from 'react'
import { isMobile } from "react-device-detect"
import s from './Home.module.scss'

import MainLayout from 'layouts/Main'
import Desktop from 'components/wireframe/Desktop'
import Mobile from 'components/wireframe/Mobile'
import Modal from 'components/wireframe/Modal'

const Home = ({children}) => {
  const [showModal, setShowModal] = useState(false)
  // This page should not show on production. Instead, users
  const shouldRedirect = (
    window?.location?.href &&
    window.location.href.includes('hefebackcountry.com')
  )
  if (shouldRedirect && window.location.href.includes('stage')) {
    window.location.replace('https://ar-banner-stage.14four.io/')
  } else if (shouldRedirect) {
    window.location.replace('https://ar-banner.14four.io/')
  }
  return (
    <MainLayout>
      <div className={showModal ? s.HomeShowModal : s.Home}>
        { isMobile
          ? <Mobile/>
          : <Desktop setShowModal={setShowModal} />
        }
        <Modal close={() => { setShowModal(false) }} active={showModal} />
    </div>
    </MainLayout>
  )
}

export default Home
