import EventBus from "scripts/EventBus";

export default new (class ARPipeline {
  constructor() {
    this.name = "internal-ar-pipeline";
  }
  onStart = () => {
    EventBus.dispatch("Action", { type: "loadingDone" });
  };
})();
