import React, { useEffect } from 'react'
import s from './AR.module.scss'
import {
  useLocation
} from 'react-router-dom'

import { use100vh } from 'react-div-100vh'


const AR = ({type, handleAction, children}) => {

  const location = useLocation()
  useEffect(() => {
    console.log('AR Location', location)
  }, [location])

  const height = use100vh()

  return (
    <>
      <div style={{height}} className={s.AR}>
        {children}
      </div>
    </>
  )

}

export default AR
